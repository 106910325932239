<template>
  <div class="content">
    <div class="row">
      <div class="col-md-2">
        <h4>Categories</h4>
        <ul class="nav nav-pills flex-column push">
          <li v-for="setting in settings" :key="setting._id" class="nav-item mb-1">
            <a class="nav-link" :class="{ active: selectedSetting._id === setting._id }" href="#" @click.prevent="onSelectSetting(setting._id)">{{
              setting.name
            }}</a>
          </li>
        </ul>
      </div>
      <div class="col-md-10">
        <div class="block block-rounded block-fx-pop" data-toggle="appear">
          <div class="block-content block-content-full">
            <div class="row mb-3">
              <div class="col-md-6">
                <p class="font-w600 font-size-h4 mb-3">{{ selectedSetting.name }}</p>
              </div>
              <div class="col-md-6 text-right">
                <button class="btn btn-primary" @click="onClickSave">Save</button>
              </div>
            </div>
            <div v-if="selectedSetting.type === 'array'">
              <div v-for="(setting, index) in selectedSetting.values" :key="setting.id">
                <div v-for="(schemaValue, key) in selectedSetting.formSchema" :key="key">
                  <FormGroup
                    :id="`${selectedSetting._id}-${key}-${index}`"
                    :type="schemaValue.type"
                    :value="setting[key]"
                    :label="schemaValue.title"
                    :options="schemaValue.options"
                    :date-format="schemaValue.dateFormat"
                    :date-min-view-mode="schemaValue.dateViewMode"
                    @input="val => onChangeArrayValue(key, index, val)"
                  />
                </div>
                <button v-if="selectedSetting.name != 'general'" class="btn btn-danger mb-5" @click="value => handleDelete(value, setting)">
                  <i class="fa fa-ban fa-fw"></i> Delete
                </button>
              </div>
            </div>
            <div v-else-if="selectedSetting.type === 'object'">
              <div v-for="(value, key) in selectedSetting.values" :key="key">
                <FormGroup
                  :id="`${selectedSetting._id}-${selectedSetting.formSchema[key].title}`"
                  :type="selectedSetting.formSchema[key].type"
                  :value="value"
                  :label="selectedSetting.formSchema[key].title"
                  :options="selectedSetting.formSchema[key].options"
                  :date-format="selectedSetting.formSchema[key].dateFormat"
                  :date-min-view-mode="selectedSetting.formSchema[key].dateViewMode"
                  @input="val => onChangeValue(key, val)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmModal
      prevent
      :open="modals[selectedSetting.name]"
      :title="'Create new ' + selectedSetting.friendlyName + ' setting'"
      :text="'Create new ' + selectedSetting.friendlyName + ' setting'"
      @close="modals[selectedSetting.name] = false"
      @submit="onSubmitNew"
    >
      <Spinner v-if="loadingAction.update">Updating...</Spinner>
      <div v-else>
        <div v-if="selectedSetting.name === 'general'">
          <div v-for="(createValue, key) in selectedSetting.create" :key="key">
            <FormGroup
              :id="createValue.title + selectedSetting.id"
              :type="createValue.type"
              :label="createValue.title"
              :options="createValue.options"
              :date-format="createValue.dateFormat"
              :date-min-view-mode="createValue.dateViewMode"
              @input="value => handleNewInput(value, key, createValue.options)"
            />
          </div>
        </div>
        <div v-for="(schemaValue, key) in selectedSetting.formSchema" v-else :key="key">
          <FormGroup
            :id="schemaValue.title + selectedSetting.id"
            :type="schemaValue.type"
            :label="schemaValue.title"
            :options="schemaValue.options"
            :date-format="schemaValue.dateFormat"
            :date-min-view-mode="schemaValue.dateViewMode"
            @input="value => handleNewInput(value, key, schemaValue.options)"
          />
        </div>
      </div>
    </ConfirmModal>
    <ConfirmModal prevent :open="modals.delete" title="Delete" text="Are you sure?" @close="modals.delete = false" @submit="onSubmitDelete">
      <Spinner v-if="loadingAction.update">Deleting...</Spinner>
    </ConfirmModal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import FormGroup from '@/components/FormGroup';
import ConfirmModal from '@/components/ConfirmModal';
import Spinner from '@/components/Spinner';

export default {
  name: 'AdminSettings',
  components: {
    FormGroup,
    ConfirmModal,
    Spinner
  },
  data() {
    return {
      selectedSettingId: null,
      selectedDeleteID: '',
      newSetting: {},
      modals: {
        delete: false
      },
      form: {}
    };
  },
  computed: {
    ...mapGetters({
      settings: 'settings/settings',
      loadingAction: 'settings/loadingAction'
    }),
    selectedSetting() {
      const setting = (this.settings || []).find(s => s._id === this.selectedSettingId);

      return setting || {};
    }
  },
  async mounted() {
    await this.listSettings({ data: { params: { $sort: 'sideBarPosition:1' } } });
    this.selectedSettingId = this.settings[0]._id;
    this.resetForm();
  },
  methods: {
    ...mapActions({
      listSettings: 'settings/list',
      updateSettings: 'settings/update',
      createReport: 'settings/create'
    }),
    onClickSave() {
      this.updateSettings({
        id: this.selectedSetting._id,
        data: {
          values: { ...this.form }
        }
      });
    },
    onChangeArrayValue(key, index, val) {
      this.$set(this.form[key], index, val);
    },
    onChangeValue(key, val) {
      this.form[key] = val;
    },
    onSelectSetting(id) {
      this.selectedSettingId = id;
      this.resetForm();
    },
    resetForm() {
      this.form = Array.isArray(this.selectedSetting.values) ? [...this.selectedSetting.values] : { ...this.selectedSetting.values };
    },

    // 2
    async onUpdate() {
      await this.updateSettings({ id: this.selectedSetting._id, data: this.selectedSetting });
      this.$toasted.success('Updated successfully!', { position: 'bottom-right', duration: 3000 });
    },
    async onSubmitNew() {
      const inputsFilled = Object.values(this.newSetting[this.selectedSetting.name]).every(value => value !== null);
      if (!inputsFilled) {
        this.$toasted.error('Please complete all inputs');
      } else {
        if (this.selectedSetting.name === 'general') {
          const keyTitleConversion = str => {
            let result = str[0].toLowerCase();
            for (let i = 1; i < str.length; i++) {
              if (str[i - 1] === ' ') {
                result += str[i].toUpperCase();
              } else {
                result += str[i];
              }
            }
            result = result.replace(/\s/g, '');
            return result;
          };
          const titleInputConverted = keyTitleConversion(this.newSetting.general.titleInput);
          this.selectedSetting.formSchema[titleInputConverted] = {
            type: this.newSetting.general.typeInput,
            title: this.newSetting.general.titleInput
          };
          this.selectedSetting.values[0][titleInputConverted] = '';
        } else {
          this.selectedSetting.values.push(this.newSetting[this.selectedSetting.name]);
        }

        const update = await this.updateSettings({ id: this.selectedSetting._id, data: this.selectedSetting });
        if (update) {
          this.modals[this.selectedSetting.name] = false;
          this.$toasted.success('Created new setting successfully');
        }
      }
    },
    handleInput(value, setting, key, options) {
      const index = this.selectedSetting.values.findIndex(select => select.id === setting.id);
      if (options) {
        if (options[0].type) {
          const optionsIndex = options.findIndex(option => option.value === value);
          this.selectedSetting.values[index].type = options[optionsIndex].type;
        }
      }
      this.selectedSetting.values[index][key] = value;
    },
    handleNewInput(value, key, options) {
      if (options) {
        if (options[0].type) {
          const optionsIndex = options.findIndex(option => option.value === value);
          this.newSetting[this.selectedSetting.name].type = options[optionsIndex].type;
        }
      }
      this.newSetting[this.selectedSetting.name][key] = value;
    },
    handleDelete(value, setting) {
      this.selectedDeleteID = setting.id;
      this.modals.delete = true;
    },
    handleModal() {
      this.modals[this.selectedSetting.name] = true;
    },
    async onSubmitDelete() {
      const index = this.selectedSetting.values.findIndex(value => value.id === this.selectedDeleteID);
      this.selectedSetting.values.splice(index, 1);
      const update = await this.updateSettings({ id: this.selectedSetting._id, data: this.selectedSetting });
      if (update) {
        this.modals.delete = false;
        this.$toasted.success('Deleted successfully');
      }
    }
  }
};
</script>
